import React from "react";
import Page from "../../components/Page";
import Title from '../../components/Title'
import Para from '../../components/Para'
import withRoot from '../../utils/withRoot'

const Terms
    = props => {
    return (
        <Page title="Terms and Conditions">

            <Title>Introduction</Title>

            <Para>
                In these conditions "Ashridge Technologies" refers to Ashridge Technologies Ltd.
            </Para>

            <Para>These
                terms and conditions govern your use of this website; by using this
                website, you accept these terms and conditions in full. If you
                disagree with these terms and conditions or any part of these terms
                and conditions, you must not use this website.
            </Para>


            <Para>You must be at least 13 years of age to use this website. By using
                this website and by agreeing to these terms and conditions you
                warrant and represent that you are at least 13 years of age.</Para>


            <Para>This website uses cookies. By using this website and agreeing to these
                terms and conditions, you consent to our use of cookies in
                accordance with the terms of Ashridge Technologies' <a href="/legal/cookies">cookies policy</a>.</Para>


            <Title>Licence to use website</Title>


            <Para>Unless otherwise stated, Ashridge Technologies and/or its licensors own the intellectual
                property rights in the website and material on the website. Subject
                to the licence below, all these intellectual property rights are reserved.</Para>

            <Para>You may view, download for caching purposes only, and print pages or data from the website for your
                own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
            </Para>

            <Para>You must not:</Para>

            <ul>
                <li>republish material from this website (including republication on another
                    website);
                </li>
                <li>sell, rent or sub-license material from the website;</li>
                <li>show any material from the website in public;</li>
                <li>reproduce, duplicate, copy or otherwise exploit material on this website for a
                    commercial purpose;
                </li>
                <li>redistribute material from this website except for content specifically and
                    expressly made available for redistribution.
                </li>
            </ul>

            <Para>Where content is specifically made available for redistribution, it may
                only be redistributed within your organisation.</Para>

            <Title>Acceptable use</Title>

            <Para>You must not use this website in any way that causes, or may cause,
                damage to the website or impairment of the availability or
                accessibility of the website; or in any way which is unlawful,
                illegal, fraudulent or harmful, or in connection with any unlawful,
                illegal, fraudulent or harmful purpose or activity.</Para>

            <Para>You must not use this website to copy, store, host, transmit, send, use,
                publish or distribute any material which consists of (or is linked
                to) any spyware, computer virus, Trojan horse, worm, keystroke
                logger, rootkit or other malicious computer software.</Para>

            <Para>You must not conduct any systematic or automated data collection
                activities (including without limitation scraping, data mining, data
                extraction and data harvesting) on or in relation to this website
                without Ashridge Technologies' express written consent.</Para>

            <Para>You must not use this website to transmit or send unsolicited commercial
                communications.</Para>

            <Para>You must not use this website for any purposes related to marketing
                without Ashridge Technologies' express written consent.
            </Para>

            <Title>Restricted access</Title>

            <Para>Access to certain areas of this website is restricted. Ashridge Technologies reserves the
                right to restrict access to areas of this website, or indeed
                this entire website, at Ashridge Technologies' discretion.</Para>

            <Para>If Ashridge Technologies provides you with a user ID and password to enable you to
                access restricted areas of this website or other content or services,
                you must ensure that the user ID and password are kept confidential.
            </Para>

            <Para>If Ashridge Technologies has provided you with a user ID and password, it
                may disable them in Ashridge Technologies' sole discretion
                without notice or explanation.</Para>

            <Title>User content</Title>

            <Para>In these terms and conditions, “your user content” means material
                (including without limitation text, images, audio material, video
                material and audio-visual material) that you submit to this website,
                for whatever purpose.</Para>

            <Para>You grant to Ashridge Technologies a worldwide, irrevocable, non-exclusive, royalty-free
                licence to use, reproduce, adapt, publish, translate and distribute
                your user content in any existing or future media. You also grant to
                Ashridge Technologies the right to sub-license these rights, and the right to bring
                an action for infringement of these rights.</Para>

            <Para>Your user content must not be illegal or unlawful, must not infringe any
                third party's legal rights, and must not be capable of giving rise to
                legal action whether against you or Ashridge Technologies or a third party (in each
                case under any applicable law).
            </Para>

            <Para>You must not submit any user content to the website that is or has ever
                been the subject of any threatened or actual legal proceedings or
                other similar complaint.</Para>

            <Para>Ashridge Technologies reserves the right to edit or remove any material submitted to this
                website, or stored on Ashridge Technologies' servers, or stored on third party hosting services used by
                Ashridge Technologies, or hosted or published upon
                this website.</Para>

            <Para>Notwithstanding Ashridge Technologies' rights under these terms and conditions in relation to user
                content, Ashridge Technologies does not undertake to monitor the submission of such
                content to, or the publication of such content on, this website.</Para>

            <Title>No warranties</Title>
            <Para>This website is provided “as is” without any representations or
                warranties, express or implied. Ashridge Technologies makes no representations or
                warranties in relation to this website or the information and
                materials provided on this website.
            </Para>

            <Para>Without prejudice to the generality of the foregoing paragraph, Ashridge Technologies does
                not warrant that:</Para>

            <ul>
                <li>this website will be constantly available, or available at all; or</li>
                <li>the information on this website is complete, true, accurate or non-misleading.
                </li>
            </ul>

            <Para>Nothing on this website constitutes, or is meant to constitute, advice of any
                kind. If you require advice in relation to any software-related matter you should consult an appropriate
                professional.</Para>

            <Title>Limitations of liability</Title>

            <Para>Ashridge Technologies will not be liable to you (whether under the law of contact, the law
                of torts or otherwise) in relation to the contents of, or use of, or
                otherwise in connection with, this website:</Para>

            <ul>
                <Para>to the extent that the website is provided free-of-charge, for any
                    direct loss;</Para>
                <Para>for any indirect, special or consequential loss; or</Para>
                <Para>for any business losses, loss of revenue, income, profits or anticipated
                    savings, loss of contracts or business relationships, loss of
                    reputation or goodwill, or loss or corruption of information or
                    data.</Para>
            </ul>

            <Para>These limitations of liability apply even if Ashridge Technologies has been expressly
                advised of the potential loss.</Para>

            <Title>Exceptions</Title>

            <Para>Nothing in this website disclaimer will exclude or limit any warranty implied
                by law that it would be unlawful to exclude or limit; and nothing in
                this website disclaimer will exclude or limit Ashridge Technologies' liability in
                respect of any:</Para>

            <ul>
                <li>death or personal injury caused by Ashridge Technologies' negligence;</li>
                <li>fraud or fraudulent misrepresentation on the part of Ashridge Technologies; or</li>
                <li>matter which it would be illegal or unlawful for Ashridge Technologies to exclude or
                    limit, or to attempt or purport to exclude or limit, its liability.
                </li>
            </ul>

            <Title>Reasonableness</Title>

            <Para>By using this website, you agree that the exclusions and limitations of
                liability set out in this website disclaimer are reasonable.
            </Para>

            <Para>If you do not think they are reasonable, you must not use this website.</Para>

            <Title>Other parties</Title>

            <Para>You accept that, as a limited liability entity, Ashridge Technologies has an interest in
                limiting the personal liability of its officers and employees. You
                agree that you will not bring any claim personally against Ashridge Technologies'
                officers or employees in respect of any losses you suffer in
                connection with the website.</Para>

            <Para>Without prejudice to the foregoing paragraph, you agree that the limitations
                of warranties and liability set out in this website disclaimer will
                protect Ashridge Technologies' officers, employees, agents, subsidiaries,
                successors, assigns and sub-contractors as well as Ashridge Technologies.
            </Para>

            <Title>Unenforceable provisions</Title>

            <Para>If any provision of this website disclaimer is, or is found to be,
                unenforceable under applicable law, that will not affect the
                enforceability of the other provisions of this website disclaimer.</Para>

            <Title>Indemnity</Title>

            <Para>You hereby indemnify Ashridge Technologies and undertake to keep Ashridge Technologies indemnified
                against any losses, damages, costs, liabilities and expenses
                (including without limitation legal expenses and any amounts paid by
                Ashridge Technologies to a third party in settlement of a claim or dispute on the
                advice of Ashridge Technologies' legal advisers) incurred or suffered by Ashridge Technologies
                arising out of any breach by you of any provision of these terms and
                conditions, or arising out of any claim that you have breached any
                provision of these terms and conditions.</Para>

            <Title>Breaches of these terms and conditions</Title>

            <Para>Without prejudice to Ashridge Technologies' other rights under these terms and conditions,
                if you breach these terms and conditions in any way, Ashridge Technologies may take
                such action as Ashridge Technologies deems appropriate to deal with the breach,
                including suspending your access to the website, prohibiting you from
                accessing the website, blocking computers using your IP address from
                accessing the website, contacting your internet service provider to
                request that they block your access to the website and/or bringing
                court proceedings against you.</Para>

            <Title>Variation</Title>

            <Para>Ashridge Technologies may revise these terms and conditions from time-to-time. Revised
                terms and conditions will apply to the use of this website from the
                date of the publication of the revised terms and conditions on this
                website. Please check this page regularly to ensure you are familiar
                with the current version.</Para>

            <Title>Assignment</Title>

            <Para>Ashridge Technologies may transfer, sub-contract or otherwise deal with Ashridge Technologies' rights
                and/or obligations under these terms and conditions without notifying
                you or obtaining your consent.</Para>

            <Para>You may not transfer, sub-contract or otherwise deal with your rights
                and/or obligations under these terms and conditions.
            </Para>

            <Title>Severability</Title>

            <Para>If a provision of these terms and conditions is determined by any court
                or other competent authority to be unlawful and/or unenforceable, the
                other provisions will continue in effect. If any unlawful and/or
                unenforceable provision would be lawful or enforceable if part of it
                were deleted, that part will be deemed to be deleted, and the rest of
                the provision will continue in effect.
            </Para>

            <Title>Entire agreement</Title>

            <Para>These terms and conditions constitute the
                entire agreement between you and Ashridge Technologies in relation to your use of
                this website, and supersede all previous agreements in respect of
                your use of this website.</Para>

            <Title>Law and jurisdiction</Title>

            <Para>These terms and conditions will be governed by and construed in accordance
                with English Law, and any disputes relating to these terms and
                conditions will be subject to the exclusive jurisdiction of the
                courts of England and Wales.</Para>

            <Title id="registrations">Registrations and authorisations</Title>

            <Para>Ashridge Technologies Limited   is registered as a company in England and Wales, Company Number 3073011.<br/>

            </Para>

            <Para>The registered office is at 1 Royal Road, St Albans AL1 4LQ
                United Kingdom.
            </Para>

            <Para>You can contact the company by email at paulb@ashridgetech.com.</Para>

        </Page>
    )
};

export default withRoot(Terms);
